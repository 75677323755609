<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="300"
            >
                <v-sheet
                        class="mx-auto"
                        height="170"
                        width="300"
                >
                    <div style="font-weight: bold; font-size: 1.3rem; text-align: center; padding:20px;">
                        <span style="color: #B71C1C;">모든 데이터가 삭제 됩니다.<br> 정말로 삭제 하시겠습니까?</span>
                    </div>

                    <v-col>
                        <v-btn
                                color="grey"
                                class="mx-3 white--text center-block tmp-btn"
                                style="float: right; margin-right:140px;"
                                small
                                @click="dialog = false"
                        >
                            취소
                        </v-btn>
                        <v-btn
                                color="primary"
                                class="mx-3 white--text center-block tmp-btn"
                                style="float: right; margin-right:40px;"
                                small
                                @click="delResvInfo"
                        >
                            확인
                        </v-btn>
                    </v-col>

                </v-sheet>

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col style="text-align: left;">
                                    <v-btn
                                            color="error"
                                            class="mx-4 white--text center-block"
                                            @click="btnClick(3)"
                                    >
                                        예약삭제
                                    </v-btn>

                                    <!--<v-btn
                                            color="error"
                                            class="mx-4 white&#45;&#45;text center-block"
                                            @click="btnClick(4)"
                                    >
                                        테스트
                                    </v-btn>-->

                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    예약내역
                                </v-col>
                                <v-col cols="12" md="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 600px;">
                                        <colgroup>
                                            <col style="width:10%"/>
                                            <col style="width:15%"/>
                                            <col style="width:10%"/>
                                            <col style="width:10%"/>
                                            <col style="width:10%"/>
                                        </colgroup>
                                        <tr>
                                            <th>객실명</th>
                                            <th>예약일</th>
                                            <th>기간</th>

                                            <th>기준/예약</th>
                                            <th>이용요금</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.roomList"
                                                :key="i">
                                            <td class="td">
                                                {{item.roomNm}}
                                            </td>
                                            <td class="td">
                                                {{item.resvStartDate}}
                                            </td>
                                            <td class="td">
                                                {{item.resvDateRange}}
                                            </td>

                                            <td class="td">
                                                {{item.stdNop}}/{{item.resvNop}}
                                            </td>
                                            <td class="td num-right">
                                                {{item.resvPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>전체</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="num-right">{{roomPrice | comma}}원</td>
                                        </tr>
                                    </table>
                                </v-col>
                                <template v-if="isCampItem">
                                <v-col cols="12" class="sub-title-3 mt-4">
                                    부대시설
                                </v-col>
                                <v-col cols="12" class="" md="12" class="overflow-x-auto">
                                        <table class="table-top-s table-top-s-b">
                                            <colgroup>
                                                <col style="width:30%"/>
                                                <col style="width:25%"/>
                                                <col style="width:15%"/>
                                               <col style="width:20%"/>
                                            </colgroup>
                                            <tr>
                                                <th>부대시설</th>
                                                <th>단가</th>
                                                <th>수량</th>
                                                <th>이용요금</th>
                                            </tr>
                                            <tr
                                                    v-for="(item,i) in this.campItem"
                                                    :key="i">
                                                <td>{{item.campName}}</td>
                                                <td class="num-right">{{item.campPrice | comma}}원</td>
                                                <td>
                                                    {{item.campQty}}
                                                </td>
                                                <td><span class="num-right">{{(item.campPrice * item.campQty) | comma}}</span></td>
                                            </tr>
                                        </table>
                                </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols="12" class="sub-title-3 mt-4">
                                        부대시설 : 신청내역이 없습니다.
                                    </v-col>

                                </template>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    예약자정보
                                </v-col>
                                <v-col cols="12" md="12" class="overflow-x-auto">
                                    <table class="table-left-s table-left-s-b" >
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">예약자명</th>
                                            <td class="td">
                                                <input id="resvNm" v-model="resvInfo.resvName"
                                                       placeholder="정확히 입력해주세요.">

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">휴대전화</th>
                                            <td class="td">
                                                <input id="resvPhone"
                                                       v-model="resvInfo.resvPhone"
                                                       maxlength="13"
                                                       @keyup="autoHyphen(resvInfo.resvPhone)">

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">고객메모</th>
                                            <td class="td py-4" colspan="3" style=" white-space: pre-wrap;">
                                                <textarea v-model="resvInfo.resvMemo"
                                                          style="resize: none; overflow:auto;"
                                                          wrap="on"
                                                          id="textarea1"
                                                          placeholder="고객메모를 적어 주세요."
                                                          @input="input1($event.target.value)"></textarea>
                                                <!--{{resvInfo.resvMemo}}-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color: red;">관리자메모</th>
                                            <td class="td">
                                                 <textarea v-model="resvInfo.adminMemo"
                                                           style="resize: none; overflow:auto;"
                                                           wrap="on"
                                                           id="textarea"
                                                           placeholder="관리자메모를 적어 주세요."
                                                           @input="input($event.target.value)"></textarea>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    결제정보
                                </v-col>
                                <v-col cols="12" md="12" class="overflow-x-auto">
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>

                                        <tr>
                                            <th class="th">결제금액</th>
                                            <td class="td">
                                                <template v-if="isEdit">
                                                    <input v-model="resvInfo.resvPrice" placeholder="입력해주세요."
                                                           style="color: red; text-align: right; width: 110px;"
                                                           @keyup="inputNumberFormat($event.target.value)" readonly>원
                                                    <v-btn
                                                            color="error"
                                                            class="mx-3 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="editBtn"
                                                    >
                                                        수정
                                                    </v-btn>
                                                </template>
                                                <template v-else>
                                                    <input  v-model="resvInfo.resvPrice_edit" placeholder="입력해주세요."
                                                            class="placeholder-red"
                                                           style="color: red; text-align: right;width: 110px;"
                                                           @keyup="inputNumberFormat($event.target.value)">원
                                                    <v-btn
                                                            color="error"
                                                            class="mx-3 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="editBtn"
                                                    >
                                                        취소
                                                    </v-btn>
                                                </template>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th class="th">결제수단</th>
                                            <td class="td" style="height: 39px;">
                                                {{payInfo.payTypeDesc}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">입금계좌</th>
                                            <td class="td" style="height: 39px;">
                                                {{payInfo.bank}} {{payInfo.accountNumber}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color:red;">입 금 자</th>
                                            <td class="td">
                                                <input v-model="resvInfo.payName" placeholder="정확히 입력해주세요.">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">예약완료일</th>
                                            <td class="td" style="height: 39px;">
                                                {{resvInfo.payDate}}
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>

                                <template v-if="resvGubun == '3' || resvGubun == '4'">
                                    <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                        환불정보
                                    </v-col>
                                    <v-col cols="12" md="12" class="overflow-x-auto">
                                        <table class="table-left-s table-left-s-b">
                                            <colgroup>
                                                <col style="width:25%"/>
                                                <col style="width:75%"/>
                                            </colgroup>
                                            <tr>
                                                <th class="th">환불은행</th>
                                                <td class="td">
                                                    <input v-model="resvInfo.refdBank" placeholder="정확히 입력해주세요.">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불계좌</th>
                                                <td class="td">
                                                    <input v-model="resvInfo.refdAcc" placeholder="정확히 입력해주세요.">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th" >환불예금주</th>
                                                <td class="td">
                                                    <input v-model="resvInfo.refdAccName" placeholder="정확히 입력해주세요.">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불신청일</th>
                                                <td class="td" style="height: 39px;">
                                                    {{resvInfo.refdReqDate}}

                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불비율</th>
                                                <td class="td" style="height: 39px;">
                                                    {{resvInfo.refdPer}}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th" >입금한금액</th>
                                                <td class="td">
                                                    <input v-model="resvInfo.realPrice" placeholder="정확히 입력해주세요."
                                                           style="text-align: right; width: 170px;"
                                                           @keyup="inputNumberFormat1($event.target.value)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불예상액</th>
                                                <td class="td">
                                                    <input v-model="resvInfo.refdPrice" placeholder="정확히 입력해주세요."
                                                           id="refdPrice"
                                                           style="text-align: right; width: 170px;"
                                                           @keyup="inputNumberFormat2($event.target.value)"
                                                    >
                                                    <v-btn
                                                            color="error"
                                                            class="mx-3 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="recalculation"
                                                    >
                                                        재계산
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <td style="height: 39px;">입금금액 - ( 예약금액 X 미환급비율 )</td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불일</th>
                                                <td class="td" style="height: 39px;">
                                                    <!--{{resvInfo.refdDate}}-->
                                                    <input id="refdDate"
                                                           v-model="resvInfo.refdDate"
                                                           placeholder="환불완료시 미입력한 경우 오늘날짜 자동입력"
                                                           style="width: 100%"
                                                           readonly>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">환불메모</th>
                                                <td class="td">
                                                 <textarea v-model="resvInfo.refdMemo"
                                                           style="resize: none; overflow:auto;"
                                                           wrap="on"
                                                           id="textarea2"
                                                           placeholder="환불자메모를 적어 주세요."
                                                           @input="input2($event.target.value)"></textarea>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </template>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    예약상태
                                </v-col>
                                <v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">예약상태</th>
                                            <td class="td">
                                                <select v-model="resvInfo.resvGubun">
                                                    <option v-for="item in selectList" :value="item.resvGubun">{{item.resvGubunDesc}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>

                                <v-col cols="12" class="my-1" md="12">
                                    <v-col cols="12" style="text-align: center">
                                        <v-btn
                                                color="primary"
                                                class="mx-4 white--text center-block"
                                                @click="btnClick(1)"
                                        >
                                            저장
                                        </v-btn>
                                        <v-btn
                                                class="mx-4 center-block"
                                                @click="btnClick(2)"
                                        >
                                            닫기
                                        </v-btn>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'AdminResvInfo',
        components: {},
        data: () => ({
            dialog: false,
            resvId: "",
            resvGubun: "0",
            roomList: [],
            roomPrice: 0,
            resvInfo: {
                resvPrice: 0,
                resvPrice_edit: 0,
            },
            campItem: [],
            isCampItem: false,
            autoresize: {
                type: [Boolean, String],
                default: false
            },
            isEdit: true,

            payInfo: {
                accountNumber: "",
                bank: "",
                payTypeDesc: ""

            },
            selectList: [
                {resvGubun:"1", resvGubunDesc: "예약대기"},
                {resvGubun:"2", resvGubunDesc: "입금완료"},
                {resvGubun:"3", resvGubunDesc: "환불신청"},
                {resvGubun:"4", resvGubunDesc: "환불완료"},
                {resvGubun:"5", resvGubunDesc: "대기취소"},
            ],

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            this.resvId = this.$getParameterByName('resvId');

            if (this.resvId === "") {
                //alert("객실 ID가 없습니다. 다시 시도해주세요.");
                //window.history.back();
            } else {
                this.getInfo();
            }

            setTimeout(() => {

                this.setDatePicker();

            }, 1000)

            //this.setDatePicker();
        },
        methods: {
            getInfo() {

                let fdata = {
                    resvId: this.resvId
                };

                return this.$store.dispatch("admin/getResvInfo", fdata)
                    .then((resp) => {
                        setTimeout(() => {
                            //this.resvInfo = JSON.parse(JSON.stringify(this.$store.state.admin.resvInfo));
                            this.resvInfo = resp.message[0];

                            // 결제금액
                            if(this.resvInfo.resvPrice !== undefined){
                                this.resvInfo.resvPrice = this.comma(this.resvInfo.resvPrice);
                            }

                            // 입금한 금액
                            if(this.resvInfo.realPrice !== undefined){
                                this.resvInfo.realPrice = this.comma(this.resvInfo.realPrice);
                            }

                            // 환불예상금액
                            if(this.resvInfo.refdPrice !== undefined){
                                this.resvInfo.refdPrice = this.comma(this.resvInfo.refdPrice);
                            }


                            this.resvGubun = this.resvInfo.resvGubun+"";

                            this.roomList = this.resvInfo.roomData;
                            this.campItem = this.resvInfo.campItem;

                            // 핸드폰 번호 변경
                            this.resvInfo.resvPhone = this.$setPhoneNum(this.resvInfo.resvPhone);

                            //객실 금액 계산
                            this.roomPrice = 0;
                            for(let i = 0; this.roomList.length > i; i++){

                                this.roomPrice += Number(this.roomList[i].resvPrice);

                            }

                            if (this.campItem.length > 0) {
                                this.isCampItem = true;
                            } else {
                                this.isCampItem = false;
                            }

                            // 계좌이체 무통장 구분
                            if (this.resvInfo.payType === "1") { // 계좌이체

                                this.payInfo.accountNumber = this.resvInfo.accountNumber;
                                this.payInfo.bank = this.resvInfo.bank;
                                this.payInfo.payTypeDesc = "계좌이체";


                            } else { // 무통장

                                this.payInfo.payTypeDesc = "무통장";
                                return this.$store.dispatch("admin/getComn", {comnGubun: 'accList'})
                                    .then((resp) => {
                                        setTimeout(() => {

                                            if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                                this.accList = JSON.parse(resp.message.comnCont);

                                                this.accInfo = this.accList[0];

                                                this.payInfo.accountNumber = this.accInfo.accNum + ' 예금주 ' + this.accInfo.accResvNm;
                                                this.payInfo.bank = this.accInfo.accBnkNm;


                                            }else{
                                                this.payInfo.accountNumber = "352-0170-6948-03 예금주 최정자";
                                                this.payInfo.bank = "농협";
                                            }

                                        }, 300)
                                    })
                                    .catch((err) => {
                                    })

                            }

                            this.$nextTick(() => {
                                this.init()
                                this.setSelect();
                            })

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setSelect(){
                switch (this.resvGubun) {
                    case '1' :{
                        this.selectList = [
                            {resvGubun:"1", resvGubunDesc: "예약대기"},
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];

                        break;
                    }
                    case '2' :{
                        this.selectList = [
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];

                        break;
                    }
                    case '3' : {
                        this.selectList = [
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                        ];

                        break;
                    }
                    case '4' :{
                        this.selectList = [
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                        ];
                        break;
                    }
                    case '5' : {
                        this.selectList = [
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];

                        break;
                    }
                    default: {

                        this.selectList = [
                            {resvGubun:"1", resvGubunDesc: "예약대기"},
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];
                        break;
                    }
                }
            },
            btnClick(val) {

                switch (val) {
                    case 1:{
                        this.saveresvInfo();
                        break;
                    }
                    case 2:{
                        close();
                        break;
                    }
                    case 3:{
                        this.dialog = true;
                        //this.delResvInfo();
                        break;
                    }
                    case 4: {

                        //console.log("테스트", this.resvInfo);

                        /*return this.$store.dispatch("admin/updateResv5", this.resvInfo)
                            .then((resp) => {
                                setTimeout(() => {
                                    console.log("updateResv5", resp);

                                }, 300)
                            })
                            .catch((err) => {
                            });*/
                    }

                }

            },

            saveresvInfo() {

                // 결제금액
                if(this.isEdit){
                    this.resvInfo.resvPrice = this.uncomma(this.resvInfo.resvPrice);
                }else{
                    this.resvInfo.resvPrice = this.uncomma(this.resvInfo.resvPrice_edit);
                }

                // 입금한 금액
                if(this.resvInfo.realPrice !== undefined){
                    this.resvInfo.realPrice = this.uncomma(this.resvInfo.realPrice);
                }

                // 환불예상금액
                if(this.resvInfo.refdPrice !== undefined){
                    this.resvInfo.refdPrice = this.uncomma(this.resvInfo.refdPrice);
                }

                //console.log("dd", $('#refdDate').val())

                if($('#refdDate').val() !== undefined){
                    this.resvInfo.refdDate = $('#refdDate').val();
                }

                this.resvInfo.resvPhone = this.resvInfo.resvPhone.replace(/-/g, "");

                return this.$store.dispatch("admin/updateResv", this.resvInfo)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("정보를 저장 했습니다.");

                            this.resvId = resp.message.resvId;
                            location.href = "/popup/resv/info?resvId=" + resp.message.resvId;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            delResvInfo(){

                let formdata = {
                    resvId : this.resvInfo.resvId
                };

                return this.$store.dispatch("admin/delResv", formdata)
                    .then((resp) => {
                        setTimeout(() => {
                            //alert("정보를 삭제 했습니다.");
                            alert(resp.message);
                            close();

                        }, 300)
                    })
                    .catch((err) => {
                    })

            },

            uploadImage(e) {
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {

                        this.roomImgRoute_tmp = resp.data;
                        let roomImgInfo = {
                            resvId: this.resvId,
                            roomImgOrder: "0",
                            roomImgRoute: this.roomImgRoute_tmp,
                            roomImgId: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            isAdd: true,

                        };

                        this.roomImgList.push(roomImgInfo);

                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },
            addImg() {
                if (this.roomImgRoute_tmp.length > 0) {

                    let roomImgInfo = {
                        resvId: this.resvId,
                        roomImgOrder: "0",
                        roomImgRoute: this.roomImgRoute_tmp,
                        roomImgId: "",
                        lstModPrs: this.$store.state.admin.loginInfo.usrId,
                        isAdd: true,
                    };

                } else {
                    alert("사진을 업로드 해주세요.");
                }

            },
            menuClick(route, roomGubun) {
                location.href = route + '?roomGubun=' + roomGubun;
            },
            init() {
                this.resize();
                this.resize1();
                if(this.resvGubun === 4){
                    this.resize2();
                }

            },
            input(value) {
                this.$emit('input', value);
                this.resize()
            },
            input1(value) {
                this.$emit('input', value);
                this.resize1()
            },
            input2(value) {
                this.$emit('input', value);
                this.resize2()
            },
            resize($event) {
                if (!this.autoresize) return
                this.$textarea.style.height = '1px'
                this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
            },
            resize1($event) {
                if (!this.autoresize) return
                this.$textarea1.style.height = '1px'
                this.$textarea1.style.height = `${this.$textarea1.scrollHeight + 12}px`
            },
            resize2($event) {
                if (!this.autoresize) return
                this.$textarea2.style.height = '1px'
                this.$textarea2.style.height = `${this.$textarea2.scrollHeight + 12}px`
            },
            comma(str) {
                if(str != undefined){
                    str = String(str);
                    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                }
            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(obj) {
                if(this.isEdit){
                    this.resvInfo.resvPrice = this.comma(this.uncomma(obj));
                }else{
                    this.resvInfo.resvPrice_edit = this.comma(this.uncomma(obj));
                }
            },
            inputNumberFormat1(obj) {
                    this.resvInfo.realPrice = this.comma(this.uncomma(obj));
            },
            inputNumberFormat2(obj) {
                    this.resvInfo.refdPrice = this.comma(this.uncomma(obj));
            },
            inputOnlyNumberFormat(obj) {
                obj = this.onlynumber(this.uncomma(obj));
            },
            onlynumber(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1');
            },
            editBtn(){
                this.isEdit = !this.isEdit;
            },
            autoHyphen(val) {
                this.resvInfo.resvPhone = this.resvInfo.resvPhone
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "")
            },
            recalculation(){

                let realPrice = 0;
                let refdPrice = 0;
                let resvPrice = 0;

                if(this.resvInfo.realPrice !== undefined){
                    realPrice = this.uncomma(this.resvInfo.realPrice);
                }else {
                    alert("입금한금액을 입력해 주세요.");
                    return;
                }

                if(this.isEdit){
                    if(this.resvInfo.resvPrice !== undefined){
                        resvPrice = this.uncomma(this.resvInfo.resvPrice);
                    }else {
                        alert("결제금액을 입력해 주세요.");
                        return;
                    }
                } else{
                    if(this.resvInfo.resvPrice_edit !== undefined){
                        resvPrice = this.uncomma(this.resvInfo.resvPrice_edit);
                    }else {
                        alert("결제금액을 입력해 주세요.");
                        return;
                    }
                }

                refdPrice = realPrice - (resvPrice * (100 - this.resvInfo.refdPer) / 100);

                if(refdPrice < 0) {
                    refdPrice = 0;
                }


                this.resvInfo.refdPrice = this.comma(refdPrice);

                $('#refdPrice').val(this.resvInfo.refdPrice);

            },
            setDatePicker() {
                this.$nextTick(function () {
                    let _this = this;

                    let todayYear = this.$getTodayYear()+3;

                    $('#refdDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                        showDropdowns: true,
                        drops: 'up',
                        minYear:2020,
                        maxYear:todayYear,
                    });

                    //$('#refdDate').val('');
                })
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.init()
            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo } 수파
            $textarea2() {
                return document.getElementById('textarea2')
            },
            $textarea() {
                return document.getElementById('textarea')
            },
            $textarea1() {
                return document.getElementById('textarea1')
            },

        },
    }
</script>

<style>

</style>

